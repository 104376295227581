import React, { useEffect, useRef, useState } from 'react';
import './styles/index.css';
import ball from './Sports-Newsletter-Ball.png';
import emailnewsletter from './Email-graphic.png';
import goodnewsletter from './The Good Newsletter Logo.jpg';
import { ToastContainer, toast } from 'react-toastify';
import './App.css';

function App() {
  const logoRef = useRef(null);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const checkedCount = [isChecked1, isChecked2, isChecked3].filter(Boolean).length;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const showToast = (message) => {
    toast(message, {
      className: "successtoast",
      position: "top-center",
      autoClose: 2200,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const requests = [];
    const utmParameters = {
      utm_source: "The Daily Aus",
      utm_medium: "Newsletterpage",
      utm_channel: "Newsletter",
      utm_campaign: "Website",
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        ...utmParameters
      }),
    };
  
    if (isChecked1) {
      requests.push(fetch('https://api-staging-thedailyaus.up.railway.app/api/subscribe/dailyaus', requestOptions));
    }
    if (isChecked2) {
      requests.push(fetch('https://api-staging-thedailyaus.up.railway.app/api/subscribe/sports', requestOptions));
    }
    if (isChecked3) {
       requests.push(fetch('https://api-staging-thedailyaus.up.railway.app/api/subscribe/thegoodnewsletter', requestOptions));
    }
    if (requests.length === 0) {
      showToast('Please select at least one newsletter option.');
      return;
    }
  
    try {
      setIsLoading(true);
      const responses = await Promise.all(requests);
      
      // First check all responses are okay
      const allSuccessful = responses.every(res => res.ok);
      const jsonResponses = await Promise.all(responses.map(res => res.json()));
  
      if (allSuccessful) {
        setShowSuccessMessage(true);
        // window.location.href = 'https://thedailyaus.com.au/success';
       } else {
        // Constructing error messages based on the responses
        const errorMessages = jsonResponses.map((res, index) => res.error || 'Unknown error at request ' + (index + 1));
        showToast('Some subscriptions failed. ' + errorMessages.join(', '));
      }
    } catch (error) {
      showToast('Network or server error');
    } finally {
      setIsLoading(false);
    }
  };  

  useEffect(() => {
    if (logoRef.current) {
      logoRef.current.classList.add('logo-entrance');
    }
  }, []);

  return (
    <div className="site-content">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="hero">
        <h1 className="newsletterpageh1">Get the best of TDA, free and direct.</h1>
      </div>
      <div className="newsletter-section">
      <h3 className="newsletterpageh3">Step 1: Select the newsletters to sign up to:</h3>
        <div className="newsletter-container">
          <div className={`newsletter-block ${isChecked1 ? 'active-background' : ''}`}>
            <div className="newsletter-block-wrapper">
          <h4>Monday - Saturday</h4>
            <input
              type="checkbox"
              id="toggleStyle1"
              className="toggle-style-checkbox"
              checked={isChecked1}
              onChange={() => setIsChecked1(!isChecked1)}
            />
            </div>
            <h3>TDA News</h3>
            <div className="flexcontainer">
            <p>Become smarter in 5 minutes with TDA’s flagship newsletter. It gives you a round-up of the day’s news in a quick and accessible way. We’ve been told it’s even easier to digest than your morning coffee (and it’s free, did we mention?).</p>
            <img className="newsletter-img" src={emailnewsletter} alt="Email newsletter" />
            </div>
             <a href="https://www.newsletter.thedailyaus.com.au" target={"_blank"} rel="noreferrer">View previous editions here</a>
          </div>
          <div className={`newsletter-block ${isChecked2 ? 'active-background' : ''}`}>
          <div className="newsletter-block-wrapper">
          <h4>Monday - Friday</h4>
            <input
              type="checkbox"
              id="toggleStyle2"
              className="toggle-style-checkbox"
              checked={isChecked2}
              onChange={() => setIsChecked2(!isChecked2)}
            />
            </div>
            <h3>TDA Sport</h3>
            <div className="flexcontainer">
            <p>Sport is for everyone. And we’ve made a newsletter to reflect that. It is sport news presented in a quick and accessible way.</p>
            <img className="newsletter-img" src={ball} alt="Sports newsletter" />
            </div>
            <a href="https://sport.thedailyaus.com.au/" target={"_blank"} rel="noreferrer">View previous editions here</a>
          </div>

          {/* new */}
          <div className={`newsletter-block ${isChecked3 ? 'active-background' : ''}`}>
            <div className="newsletter-block-wrapper">
          <h4>Sunday</h4>
            <input
              type="checkbox"
              id="toggleStyle1"
              className="toggle-style-checkbox"
              checked={isChecked3}
              onChange={() => setIsChecked3(!isChecked3)}
            />
            </div>
            <h3>The Good Newsletter</h3>
            <div className="flexcontainer">
            <p>The Good Newsletter is your weekly wrap of the week's good news. You can expect to read about anything from the latest scientific discovery to everyday people doing good things. It's your weekly reminder to always look on the bright side!</p>
            <img className="newsletter-img" src={goodnewsletter} alt="Email newsletter" />
            </div>
             <a href="https://thegoodnewsletter.thedailyaus.com.au" target={"_blank"} rel="noreferrer">View previous editions here</a>
          </div>

        </div>
        <h3 className="newsletterpageh3">Step 2: Enter your email below and click subscribe. That's it!</h3>
        <div className="signup-block">
          <div className="signup-wrapper">
          <div className={`comingtoyourinbox ${isChecked1 || isChecked2 ? 'active-background' : ''}`}>
            {checkedCount}
          </div>
            <label htmlFor="email">Coming to your inbox</label>
          </div>
          <div className="input-wrapper">
            <form onSubmit={handleSubscribe}>
            <input 
            type="email" 
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email" />
            <button type="submit" disabled={isLoading}>{isLoading ? 'Loading...' : 'Subscribe'}</button>
            </form>
          </div>
          {showSuccessMessage && <h3>You have successfully subscribed.</h3>}
        </div>
      </div>
      </div>
  );
}

export default App;
